import Vue from 'vue';

export function bspTableFilter(value, obj,isLine) {
  if(isLine&&!value){
    return '--'
  }
  if(obj['1'] === 'dict'){
    // 代码值的翻译
    // 查看value 中有没有‘,’如果有 则分隔来翻译
    let valStr = value+'';
    let split = valStr.split(",");
    let v='' ;
    if(split.length>1){
      // 多个代码值翻译
      for (let i = 0,l=split.length; i <l; i++) {
        if(i==l-1){
          v+=dictFilter(split[i], obj['2']);
        }else {
          v+=dictFilter(split[i], obj['2'])+" / ";
        }
      }
      return v;
    }else {
      // 单个代码值翻译
     return dictFilter(value, obj['2']);
    }
  } else if(obj['1'] === 'date'){
    // 日期类型的格式化
    if(value){
      return Vue.moment(value).format('YYYY-MM-DD');
    }
    return value;
  } else if(obj['1'] === 'ref'){

    // 引用类型的翻译
    let data = obj['3']
    //console.log("引用数据：=========",data,value);
    //let key  = obj['4']
    return refDataFilter(value,data);
  } else  {
    return value
  }
}

export function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return `${time + label}s`;
}

export function filePathFormat(path, defaultFileId) {
  let newPath = '';
  if (path) {
    newPath = process.env.VUE_APP_BASE_API + `/file/${path}`;
  } else if (defaultFileId) {
    newPath = process.env.VUE_APP_BASE_API +`/file/${defaultFileId}`;
  } else {
    newPath = '';
  }
  return newPath;
}

/**
 *引用类型翻译
 * @param codeId
 * @param dicts
 * @returns {*}
 */
export function refDataFilter(value, refDatas) {
  if (refDatas) {
    return refDatas[value];
  }else {
    return"";
  }
}

/**
 * 单个代码值列表翻译
 * @param codeId  代码项id
 * @param dicts 前端存储的所有的代码
 * @returns {string|*}
 */
export function dictFilter(codeId, dicts,isline) {
  if(!codeId&&isline){
    return '--'
  }
  if (dicts && dicts.codeMap) {
    if(codeId===0||codeId=="0"){
      return "";
    }else {
      return dicts.codeMap[codeId]? dicts.codeMap[codeId]:codeId;
    }
  }else if(dicts){
    return dicts[codeId]
  }
}

//过滤操作模式
export function dictFilterOperMode(codeId, dicts,obj) {
  if (dicts && dicts.codeMap) {
    if(codeId===0||codeId=="0"){
      return "";
    }else {
      return dicts.codeMap[codeId]? dicts.codeMap[codeId]:codeId;
    }
  }else if(dicts){
    let value=dicts[codeId];
    let valStr = value+'';
    let split = valStr.split(",");
    let v='' ;
    if(split.length>1) {
      // 多个代码值翻译
      for (let i = 0, l = split.length; i < l; i++) {
        if (i == l - 1) {
          v += dictFilter(split[i], obj['2']);
        } else {
          v += dictFilter(split[i], obj['2']) + " / ";
        }
      }
      return v;
    }else {
      // 单个代码值翻译
      return dictFilter(value, obj['2']);
    }
  }
}

/**
 *代码集 作废
 * @param codeId
 * @param dicts
 * @returns {*}
 */
export function dictListFilter(codeId, dicts) {
  if (dicts && dicts.length > 0) {
    for (let i = 0; i < dicts.length; i ++) {
      let m = dicts[i];
      if (m["codeItemId"] == codeId) {
        return m["codeItemName"];
      }
    }
  }else
    return codeId;
}


/**
 * 日期格式化
 */
export function dateFormat(date) {
  let format = 'yyyy-MM-dd hh:mm:ss';
  if (date != 'Invalid Date') {
    const o = {
      'M+': date.getMonth() + 1, // month
      'd+': date.getDate(), // day
      'h+': date.getHours(), // hour
      'm+': date.getMinutes(), // minute
      's+': date.getSeconds(), // second
      'q+': Math.floor((date.getMonth() + 3) / 3), // quarter
      S: date.getMilliseconds(), // millisecond
    };
    if (/(y+)/.test(format)) {
      format = format.replace(RegExp.$1,
        (`${date.getFullYear()}`).substr(4 - RegExp.$1.length));
    }
    for (const k in o) {
      if (new RegExp(`(${k})`).test(format)) {
        format = format.replace(RegExp.$1,
          RegExp.$1.length == 1 ? o[k] :
            (`00${o[k]}`).substr((`${o[k]}`).length));
      }
    }
    return format;
  }
  return '';
}
export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute');
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour');
  }
  return pluralize(~~(between / 86400), ' day');
}

export function parseTime(time, cFormat) {
  time = Date.parse(new Date(time));
  if (arguments.length === 0) {
    return null;
  }

  if ((`${time}`).length === 10) {
    time = +time * 1000;
  }

  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    date = new Date(parseInt(time));
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1];
    if (result.length > 0 && value < 10) {
      value = `0${value}`;
    }
    return value || 0;
  });
  return time_str;
}

export function formatTime(time, option) {
  time = +time * 1000;
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) { // less 1 hour
    return `${Math.ceil(diff / 60)}分钟前`;
  } else if (diff < 3600 * 24) {
    return `${Math.ceil(diff / 3600)}小时前`;
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  }
  return `${d.getMonth() + 1}月${d.getDate()}日${d.getHours()}时${d.getMinutes()}分`;
}


/* 数字 格式化 */
export function formatNumber(num, digits) {
  if (num) {
    num = num.toFixed(digits || 2);
  } else if (num === 0) {
    num = '0.00';
  }
  return num;
}

/* 数字 格式化 */
export function nFormatter(num, digits) {
  const si = [
    { value: 1E18, symbol: 'E' },
    { value: 1E15, symbol: 'P' },
    { value: 1E12, symbol: 'T' },
    { value: 1E9, symbol: 'G' },
    { value: 1E6, symbol: 'M' },
    { value: 1E3, symbol: 'k' },
  ];
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol;
    }
  }
  return num.toString();
}

export function html2Text(val) {
  const div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

export function toThousandslsFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
}

/* 金额格式化 */
export function moneyFilter(val) {
  if(val==null) val='0.00';
  if(typeof(val) != "number"){
    val = val.cellValue
  }
 let value = val + ''
  if(!value) return '0.00';

  /*原来用的是Number(value).toFixed(0)，这样取整时有问题，例如0.51取整之后为1*/
  let intPart =  Number(value)|0; //获取整数部分
  let intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); //将整数部分逢三一断

  let floatPart = ".00"; //预定义小数部分
  let value2Array = value.split(".");

  //=2表示数据有小数位
  if(value2Array.length == 2) {
    floatPart = value2Array[1].toString(); //拿到小数部分

    if(floatPart.length == 1) { //补0,实际上用不着
      return intPartFormat + "." + floatPart + '0';
    } else {
      return intPartFormat + "." + floatPart;
    }

  } else {
    return intPartFormat + floatPart;
  }
}

/* 金额格式化(字符串) */
export function moneyFilterStr(val) {
  if(val==null) val='0.00';
  let value = val;
  if(value == "0.00") return '0.00';

  /*原来用的是Number(value).toFixed(0)，这样取整时有问题，例如0.51取整之后为1*/
  let intPart =  Number(value)|0; //获取整数部分
  let intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); //将整数部分逢三一断

  let floatPart = ".00"; //预定义小数部分
  let value2Array = value.split(".");

  //=2表示数据有小数位
  if(value2Array.length == 2) {
    floatPart = value2Array[1].toString(); //拿到小数部分

    if(floatPart.length == 1) { //补0,实际上用不着
      return intPartFormat + "." + floatPart + '0';
    } else {
      return intPartFormat + "." + floatPart;
    }

  } else {
    return intPartFormat + floatPart;
  }
}

