/**
 * 平台菜单
 */

export default [
  {
    label: 'DEMO',
    icon: 'icon-caidan',
    permission:'wage_demo_menu',
    id:'15',
    children: [
      {
        label: 'CRUD',
        icon: 'icon-yonghu',
        path: '/serve/table',
        permission: 'wage_demo_menu',
        id:'15-1',
      },
      {
        path: '/serve/dynamicTable',
        label: '动态表单',
        permission: 'wage_demo_menu',
        icon: 'icon-yonghu',
        id:'15-2'
      },

      {
        path: '/table',
        label: '表格内编辑',
        icon: 'icon-yonghu',
        permission: 'wage_demo_menu',
        id:'15-3'
      },
      {
        path: '/import/excle',
        label: 'Excle上传',
        icon: 'icon-yonghu',
        permission: 'wage_demo_menu',
        id:'6-4'
      },
      {
        path: '/tabs',
        label: '动态页签',
        icon: 'icon-yonghu',
        permission: 'wage_demo_menu',
        id:'15-5'
      },
      {
        path: '/serve/groupTable',
        label: '多级列头',
        permission: 'wage_demo_menu',
        icon: 'icon-yonghu',
        id:'15-6'

      },
      {
        path: '/static/form',
        label: '静态表单',
        permission: 'wage_demo_menu',
        icon: 'icon-yonghu',
        id: '15-7'
      },
      {
        path: '/form/customerCenter',
        label: '客户中心',
        permission: 'wage_demo_menu',
        icon: 'icon-yonghu',
        id:'15-8'
      },
      {
        path: '/socket/msg',
        label: '消息驱动',
        permission: 'wage_demo_menu',
        icon: 'icon-yonghu',
        id: '15-9'
      },
      {
        path: '/batchParent',
        label: '批量导入',
        permission: 'wage_demo_menu',
        icon: 'icon-yonghu',
        id:'15-8'
      },
      {
        path: '/ceshi',
        label: '测试',
        permission: 'wage_demo_menu',
        icon: 'icon-yonghu',
        id:'15-8'
      },
      {
        path: '/websocket',
        label: 'WebSocket测试',
        permission: 'wage_demo_menu',
        icon: 'icon-yonghu',
        id:'15-10'
      },
      {
        path: '/salary_template',
        label: '薪资模板',
        permission: 'wage_demo_menu',
        icon: 'icon-yonghu',
        id:'15-11'
      },
      {
        path: '/PaySlip',
        label: '工资条',
        permission: 'wage_demo_menu',
        icon: 'icon-yonghu',
        id:'15-12'
      },
      // {
      //   path: '/salarySet',
      //   label: '薪酬设置',
      //   permission: 'wage_demo_menu',
      //   icon: 'icon-yonghu',
      //   id:15-13
      // },
      {
        path: '/dataImport',
        label: '数据导入的大表格',
        permission: 'wage_demo_menu',
        icon: 'icon-yonghu',
        id:'15-13'
      },
      {
        path: '/chooseBox',
        label: '数据选择框',
        permission: 'wage_demo_menu',
        icon: 'icon-yonghu',
        id:'15-14'
      },
      {
        path: '/payrollSetting',
        label: '工资单设置',
        permission: 'wage_demo_menu',
        icon: 'icon-yonghu',
        id:'15-14'
      },
      {
        path: '/invoiceDemo',
        label: '发票拆分',
        permission: 'wage_demo_menu',
        icon: 'icon-yonghu',
        id:'15-15'
      },
      {
        path: '/treeSelect',
        label: '树形选择框',
        permission: 'wage_demo_menu',
        icon: 'icon-yonghu',
        id:'15-16'
      },
      {
        path: '/dataImport1',
        label: '数据导入',
        permission: 'wage_demo_menu',
        icon: 'icon-yonghu',
        id:'15-17'
      },
      {
        path: '/borrow',
        label: '借发票',
        permission: 'wage_demo_menu',
        id:'15-18'
      },
      {
        path: '/wageSplit',
        label: '工资拆分',
        permission: 'wage_demo_menu',
        id:'15-19'
      },
    ],
  },
];

