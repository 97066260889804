export default [
  /*{
    path: 'wt/detailImportBatchTabs',
    name: '批次列表',
    component: () => import ( /!* webpackChunkName: "serveTable" *!/ `@/views/cus/leads/leadsTabs.vue`),
  },*/

  {
    name: '客户对比表',
    path: '/rpt/rptAction_cusCompareIndex.action',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/pub/pubIndex.vue`),
  },
  {
    name: '支出单批次',
    path: '/rpt/payoutBillsBatch',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/report/payoutBills/payoutBillsBatchList.vue`),
  },
  {
    name: '服务费收入与成本对比',
    path: '/rpt/rptAction_serviceFeeCompare.action',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/pub/pubIndex.vue`),
  },
  {
    name: '服务费核对表',
    path: '/rpt/rptAction_serviceFeeCheckIndex.action',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/pub/pubIndex.vue`),
    id:'6-3',
  },
  {
    name: '委托记录统计',
    path: '/rpt/rptAction_consignorRecordList.action',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/pub/pubIndex.vue`),
  },
  {
    name: '客户分润',
    path: '/wt/socialSecurityImportList',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/entrust/socialSecurityImport/import/socialSecurityImp.vue`),
  },

];
