/**
 * 公共变量
 */
export const commonConst = {
  TENANT_ID: '2',  //租户id
  
  // 是 否
  YES:'10020001',
  NO:'10020002',

  // 计算处理中
  PROCESSING: '10020003',

  // 代报销发放批次
  BATCH_BAOXIAO: '30500002',

  //薪资批次流程状态
  NEW_BATCH: '30680001', //新增
  WAITING_BILL: '30680002', //待生成账单
  WAITING_INVOICE: '30680003', //待生成发票
  WAITING_CONFIRM_BILL: '30680004', //待认单

  // 数据类型
  INTGER: '1', //整数
  FLOAT: '2', //小数
  TEXT: '3', //文本
  CODE: '6', //代码值
};

