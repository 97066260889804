export default [
  {
    path: '/agreement/agreement_agreementIndex.action',
    name: '协议管理',
    component: () => import ( /* webpackChunkName: "table" */ `@/views/pub/pubIndex.vue`),
  },
  {
    path: '/agreementInfo/agreementInfo_applyIndex.action',
    name: '申请管理',
    component: () => import ( /* webpackChunkName: "table" */ `@/views/pub/pubIndex.vue`),
  },
  {
    path: '/agree/finTaxAgreementApplyIndex',
    name: '财税申请管理',
    component: () => import ( /* webpackChunkName: "table" */ `@/views/agreement/fintax/apply/finTaxAgreementApplyIndex.vue`),
  },
  {
    path: '/agree/finTaxAgreementIndex',
    name: '财税协议管理',
    component: () => import ( /* webpackChunkName: "table" */ `@/views/agreement/fintax/manage/finTaxAgreementIndex.vue`),
  },
  {
    path: '/agreement/agreement_agreementPriceIndex.action',
    name: '委托价格审批',
    component: () => import ( /* webpackChunkName: "table" */ `@/views/pub/pubIndex.vue`),
  },
  {
    path: '/*.action',
    name: '委托价格审批-审批',
    component: () => import ( /* webpackChunkName: "table" */ `@/views/pub/pubIndex.vue`),
  },
  {
    path: '/priceInfo/priceInfo_getAllPriceInfoIndex.action',
    name: '对公委托价格表',
    component: () => import ( /* webpackChunkName: "table" */ `@/views/pub/pubIndex.vue`),
  },
];
