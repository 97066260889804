export default [
  {
    path: 'wt/detailImportBatchTabs',
    name: '批次列表',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/entrust/detailImport/detailImportBatchTables.vue`),
  },
  {
    path: 'wt/detailImportTables',
    name: '批次对应明细列表',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/entrust/detailImport/detailImportTables.vue`),
  },
  {
    path: 'wt/importDetail',
    name: '导入',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/entrust/detailImport/import/batchParent.vue`),
  },
  {
    path: 'entrust/socialSecurityImportList',
    name: '社保导入',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/entrust/socialSecurityImport/socialSecurityBatchList.vue`),
  },
  {
    path: 'entrust/importSocialSecurity',
    name: '点击导入',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/entrust/socialSecurityImport/import/socialSecurityImp.vue`),
  },
  {
    name: '支出单管理',
    path: '/consignor/payoutBillsIndex.action',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/pub/pubIndex.vue`),
  },
  {
    name: '委托批次管理',
    path: '/consignor/batchIndex.action',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/pub/pubIndex.vue`),
  },
  // {
  //   name: '退票管理',
  //   path: '/consignor/payoutBills_packedRTicket.action',
  //   component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/pub/pubIndex.vue`),
  // },
  {
    name: '退票管理',
    path: '/wt/packedRTicketTabs',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/entrust/RTicket/packedRTicketTabs.vue`),
  },
  // {
  //   name: '导入模板更名',
  //   path: '/trusted/wageTemplate_forwardImportTemplateOfOrg.action',
  //   component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/pub/pubIndex.vue`),
  // },
  {
    name: '导入模板更名',
    path: '/entrust/importTemplateRename',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/entrust/template/importTemplateRename.vue`),
  },
  /*{
    name: '工资条查询',
    path: '/wageBills/wageBillsIndex.action',
    component: () => import ( /!* webpackChunkName: "serveTable" *!/ `@/views/pub/pubIndex.vue`),
  },*/
  {
    name: '工资条查询',
    path: '/wageBills/wageBillsPage',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/entrust/wage/wageBillsPage.vue`),
  },
  {
    name: '委托历史记录查询',
    path: '/consignee/batchAction_historyBizTypeIndex.action?operateFlag=MTAwMA--',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/pub/pubIndex.vue`),
  },
  {
    name: '汇款差额查询',
    path: '/consignor/differenceIndex.action',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/pub/pubIndex.vue`),
  },
  {
    name: '专项导入',
    path: '/special/trustedImportSpecial_special.action',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/pub/pubIndex.vue`),
  },
  { path: '/entrust/socialSecurityDetail',
    name: '社保明细',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/entrust/socialSecurityImport/detail/socialSecurityList.vue`),
  }

  ,
  {
    path: '/entrust/paymentBills',
    name: '账单',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/entrust/paymentBill/paymentBill.vue`),
  }
  ,
  {
    path: '/entrust/paymentBillDetail',
    name: '账单明细',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/entrust/paymentBill/detail/detailBill.vue`),
  }
  ,
  {
    path: '/trusted/trustedImport_empInfoWarn.action',
    name: '待完善雇员提醒',
    component: () => import ( /* webpackChunkName: "table" */ `@/views/pub/pubIndex.vue`),
  },
  {
    path: 'wt/buTieTabs',
    name: '补贴',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/entrust/butie/buTieTables.vue`),
  },
  {
    path: 'wt/buTieByBatchIdList',
    name: '补贴批次列表',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/entrust/butie/buTieByBatchIdList.vue`),
  },
  {
    path: 'entrust/distributionBatchIndex',
    name: '支付确认',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/entrust/distributionbatch/distributionBatchIndex.vue`),
  },
  {
    path: 'wt/averagewage',
    name: '平均工资',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/entrust/detailImport/detailImportAverageWage.vue`),
  },
  {
    path: 'wt/copyParent',
    name: '复制校验主页面',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/entrust/detailImport/copyBatch/copyParent.vue`),
  },
  {
    path: 'wt/copySalaryDetail',
    name: '复制校验主页面',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/entrust/detailImport/copyBatch/copySalaryDetail.vue`),
  },
  {
    path: 'wt/entrustPrice',
    name: '委外服务查询',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/entrust/price/entrustPrice.vue`),
  },
];
