export default [
  /*{
    path: 'wt/detailImportBatchTabs',
    name: '批次列表',
    component: () => import ( /!* webpackChunkName: "serveTable" *!/ `@/views/cus/leads/leadsTabs.vue`),
  },*/
  {
    name: '基础设置',
    path: '',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/entrust/socialSecurityImport/import/socialSecurityImp.vue`),
  },
  {
    name: '完税信息',
    path: '',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/entrust/socialSecurityImport/import/socialSecurityImp.vue`),
  },
  {
    name: '工具',
    path: '/wt/socialSecurityImportList',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/entrust/socialSecurityImport/import/socialSecurityImp.vue`),
  },
  {
    name: '权限管理',
    path: '',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/entrust/socialSecurityImport/import/socialSecurityImp.vue`),
  },
  {
    name: '参数管理',
    path: '/parameterInfo/parameterInfo_paramInfoIndex.action',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/pub/pubIndex.vue`),
  },
  {
    name: '政策管理',
    path: '/policy/policyList',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/policy/policyList.vue`),
  },
  {
    name: '系统配置',
    path: '/sysConf/sysConfig',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/sysConf/sysConfig.vue`),
  },

  {
    name: '模拟登录',
    path: '/sysMaintenance/imitation_login',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/sysMaintenance/mitationLogin.vue`),
  },
  {
    name: '公告配置',
    path: '/sysMaintenance/pub_notice',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/sysMaintenance/pubNotice.vue`),
  },
  {
    name: '审计日志',
    path: '/audit/aduitLog',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/audit/auditList.vue`),
  },
  {
    name: '访问日志',
    path: '/audit/loginLog',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/audit/loginList.vue`),
  },
  {
    name: '通知',
    path: '/sysMaintenance/pub_notice_detail',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/sysMaintenance/pubNoticeDetail.vue`),
  },

  {
    path: '/taxArea/taxAreaTabs',
    name:'计税区域',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/taxArea/taxAreaTabs.vue`),
  }
];
