import router from './router/router';
import store from './store';
import {
  getUser ,
  loginToken
} from '@/api/user/login';
//没有登录时可以直接访问的页面
const whiteList = ['/404', '/401', '/lock', '/login', '/register'];
const loginRG = /\/[a-zA-Z0-9]*\/login$/;

router.beforeEach(async (to, from, next) => {
  console.log("=============================")
  console.log(to.query.accessToken)
  if (to.query.accessToken) {
    // 自己调用自己
    let  response  = await  loginToken(to.query.accessToken)  ;
    store.commit('SET_ACCESS_TOKEN', response.data.data.accessToken);
    // 此处需要加载登录后的其他操作
    console.log('登录测试',  response.data.data)
    const data = response.data.data.user;
    console.log('登录测试', data)
    //commit('SET_REFRESH_TOKEN', data.refresh_token);

    //commit('SET_PASS', data.pass);
    store.commit('SET_PMS', data.pmsList);
    store.commit('SET_USER_INFO', data);
    store.commit('SET_CLIENT_INFO', {id: data.clientId});
    store.commit('SET_TENANT_INFO', {id: data.tenantId});
    store.commit('SET_MEMBER_INFO', {id: data.memberId});
    store.commit('SET_AUTHORIZED_MEMBERS', data.authorizedMembers);
    store.commit('SET_AUTHORIZED_AGENCIES', data.authorizedAgencies);

    store.commit('CLEAR_LOCK');
    if(response.data.code ===0){
      router.push({path: '/index'});
      return ;
    }

  }
  if (process.env.VUE_APP_LOCAL_DEVELOPMENT == 'true') {
    next();
  } else {
    console.log("路由验权=======", loginRG.test(to.path), to.path);
    //todo 直接跳转不验权
    next();
    return;

    // 判断token是否有值，没有的话跳转到登录页
    if (store.getters.accessToken) {
      next();
    } else {
      if (whiteList.indexOf(to.path) !== -1 || loginRG.test(to.path)) {
        next();
      } else {
        next('/login')
      }
    }
  }

});


router.afterEach((to, from) => {
});

// // 解决Loading chunk 3 failed.的问题
// router.onError((error) => {
//   const pattern = /Loading chunk (\d)+ failed/g;
//   const isChunkLoadFailed = error.message.match(pattern);
//   const targetPath = router.history.pending.fullPath;
//   if (isChunkLoadFailed) {
//     location.reload();
//   }
// });
