/**
 * 菜单
 */

export default [
  {
    path: 'table',
    name: '表单内编辑',
    component: () => import ( /* webpackChunkName: "table" */ `@/views/demo/table.vue`),
  },
  {
    path: 'serve/groupTable',
    name: '表格分组',
    component: () => import ( /* webpackChunkName: "groupTable" */ `@/views/demo/serve/groupTable.vue`),
  },
  {
    path: 'serve/table',
    name: '表格',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/demo/serve/table.vue`),
  },
  {
    path: 'serve/dynamicTable',
    name: '动态表格',
    component: () => import ( /* webpackChunkName: "dynamicTable" */ `@/views/demo/serve/dynamicTable.vue`),
  },
  {
    path: 'tabs',
    name: 'tabs页签',
    component: () => import ( /* webpackChunkName: "tabs" */ `@/views/demo/tabs/tabs.vue`),
  },
  {
    path: 'form/form',
    name: 'form表单',
    component: () => import ( /* webpackChunkName: "form" */ `@/views/demo/form/form.vue`),
  },
  {
    path: 'form/dynamicForm',
    name: '动态表单',
    component: () => import ( /* webpackChunkName: "form" */ `@/views/demo/form/dynamicForm.vue`),
  },
  {
    path: 'form/customerCenter',
    name: '客户中心',
    component: () => import ( /* webpackChunkName: "form" */ `@/views/demo/form/customerCenter.vue`),
  },
  {
    path: 'batchParent',
    name: '批量导入',
    component: () => import ( /* webpackChunkName: "form" */ `@/components/ciic/batchIncrease/batchParent`),
  },
  {
    path: 'ceshi',
    name: '动态表单测试',
    component: () => import ( /* webpackChunkName: "form" */ `@/views/demo/ceshi.vue`),
  },
  {
    path: 'websocket',
    name: 'WebSocket测试',
    component: () => import ( /* webpackChunkName: "form" */ `@/views/demo/websocket/scoketTest.vue`),
  },
  {
    path: 'salary_template',
    name: '薪资模板',
    component: () => import ( /* webpackChunkName: "form" */ `@/views/demo/template/salaryTemplate.vue`),
  },
  {
    path: 'PaySlip',
    name: '工资条',
    component: () => import ( /* webpackChunkName: "PaySlip" */ `@/views/demo/PaySlip/PaySlip.vue`),
  },
  // {
  //   path: 'salarySet',
  //   name: '薪酬设置',
  //   component: () => import ( /* webpackChunkName: "salarySet" */ `@/views/demo/salarySet/salarySet.vue`),
  //   children:[
  //     {
  //       path: '/',
  //       name: '薪酬组',
  //       component: () => import ( /* webpackChunkName: "step2" */ `@/views/demo/salarySet/step2.vue`),
  //     },
  //     {
  //       path: '/salarySet/step1',
  //       name: '公司设置',
  //       component: () => import ( /* webpackChunkName: "step2" */ `@/views/demo/salarySet/step1.vue`),
  //     },
  //     {
  //       path: '/salarySet/step2',
  //       name: '薪酬组',
  //       component: () => import ( /* webpackChunkName: "step2" */ `@/views/demo/salarySet/step2.vue`),
  //     },
  //     {
  //       path: '/salarySet/step3',
  //       name: '工资模板',
  //       component: () => import ( /* webpackChunkName: "step2" */ `@/views/demo/salarySet/step3.vue`),
  //     },
  //     {
  //       path: '/salarySet/step4',
  //       name: '审批流程',
  //       component: () => import ( /* webpackChunkName: "step2" */ `@/views/demo/salarySet/step4.vue`),
  //     },
  //     {
  //       path: '/salarySet/step5',
  //       name: '工资单',
  //       component: () => import ( /* webpackChunkName: "step2" */ `@/views/demo/salarySet/step5.vue`),
  //     },
  //
  //   ]
  // },
  // {
  //   path: 'dataImport',
  //   name: '数据导入',
  //   component: () => import ( /* webpackChunkName: "dataImport" */ `@/views/demo/dataImport/dataImport.vue`),
  //
  // },
  {
    path: 'chooseBox',
    name: '数据选择框',
    component: () => import ( /* webpackChunkName: "chooseBox" */ `@/views/demo/chooseBox/chooseBoxDemo.vue`),
  },
  {
    path: 'payrollSetting',
    name: '薪资设置',
    component: () => import ( /* webpackChunkName: "payrollSetting" */ `@/views/demo/payrollSettings/payrollSetting.vue`),
  },
  {
    path: 'invoiceDemo',
    name: '发票拆分',
    component: () => import ( /* webpackChunkName: "invoiceDemo" */ `@/views/demo/invoiceSplitting/invoiceDemo.vue`),
  },
  {
    path: 'treeSelect',
    name: '树形选择框',
    component: () => import ( /* webpackChunkName: "treeSelect" */ `@/views/demo/treeSelect/treeSelect.vue`),
  },
  {
    path: 'borrow',
    name: '借发票',
    component: () => import ( /* webpackChunkName: "treeSelect" */ `@/views/demo/borrowInvoice/borrowInvoiceDemo.vue`),
  },
  {
    path: 'wageSplit',
    name: '工资拆分',
    component: () => import ( /* webpackChunkName: "treeSelect" */ `@/views/demo/wageSplit/wageSplitDemo.vue`),
  },
];
