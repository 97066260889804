export default [
  {
    path: '/orgInfo/orgInfo_orgInfoIndex.action',
    name: '机构管理',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/pub/pubIndex.vue`),
  },
  /*{
    path: '/priceInfo/priceInfo_getBalanceInfoIndex.action',
    name: '对公余额查询',
    component: () => import ( /!* webpackChunkName: "serveTable" *!/ `@/views/pub/pubIndex.vue`),
  },*/

];
