

import { setStore, getStore } from '@/util/store';

const screen = {
  state: {
    clientWidth: getStore({ name: 'clientWidth' }) || 1920,
    clientHeight: getStore({ name: 'clientHeight' }) || 1080,
    tableSize: getStore({ name: 'clientHeight' }) || 'mini',
    workspaceRect: {},
  },
  mutations: {
    SET_CLIENT_WIDTH: (state, clientWidth) => {
      state.clientWidth = clientWidth;
      setStore({
        name: 'clientWidth',
        content: state.clientWidth,
        type: 'session',
      });
    },
    SET_CLIENT_HEIGHT: (state, clientHeight) => {
      state.clientHeight = clientHeight;
      setStore({
        name: 'clientHeight',
        content: state.clientHeight,
        type: 'session',
      });
    },
    SET_TABLE_SIZE: (state) => {
      let tableSize = 'mini';
      if (state.clientWidth <= 1366) {
        tableSize = 'mini';
      } else if (state.clientWidth <= 1600) {
        tableSize = 'small';
      } else if (state.clientWidth > 1600) {
        tableSize = 'medium';
      }
      state.tableSize = tableSize;
      setStore({
        name: 'tableSize',
        content: state.tableSize,
        type: 'session',
      });
    },
    SET_WORKSPACE_RECT: (state,workspaceRect) => {
      state.workspaceRect = workspaceRect;
    },
  },

};

export default screen;
