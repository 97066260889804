import Vue from 'vue';
import App from './App';
import 'babel-polyfill';

import Element, { Message } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueMoment from 'vue-moment';

import './permission'; // 权限
import './directives'; // 引入Dialog可拖拽
import store from './store/index';
import * as filters from './filters'; // 全局filter
import './styles/common.scss';
import './styles/font.css';
import router from './router/router';
import codeSetConst from './const/codeSet';
import {getDicts} from '@/api/sys/code';
import loading from './util/loading';
import XEUtils from 'xe-utils';
import VXETable from 'vxe-table';
import VXETablePluginElement from 'vxe-table-plugin-element'
import 'vxe-table-plugin-element/dist/style.css'
import 'vxe-table/lib/index.css'
import './styles/public.scss'
import './styles/themes/themes.scss';
import './styles/setButton.scss'
import './styles/narrowUI.scss'
import './assets/icon/iconfont.js'
import VXEUtils from 'vxe-utils'
// import echarts from 'echarts'
import VideoPlayer from 'vue-video-player'

// mounts 可以设置['cookie', 'browse', 'locat']
Vue.use(VXEUtils, XEUtils, {mounts: ['cookie']})

Vue.config.productionTip = false;

Vue.use(Element,{size: 'small'});

import { message } from './resetMessage';
Vue.prototype.$message = message; //重写message提示框


Vue.use(VueMoment);
Vue.use(VXETable);
VXETable.use(VXETablePluginElement)

// 视频
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)


// 自定义全局的格式化处理函数
VXETable.formats.mixin({
  formatCode ({ cellValue }) {
    let dicts1 = JSON.parse(sessionStorage.getItem('dicts')).content;
    if (cellValue&&dicts1 && dicts1.codeMap) {
      if(cellValue ===0||cellValue =="0"){
        return "";
      }else {
        return dicts1.codeMap[cellValue ]? dicts1.codeMap[cellValue ]:cellValue ;
      }
    }else if(dicts1){
      return dicts1[cellValue ]
    }
  },
})

Vue.prototype.getDicts = getDicts;
Vue.prototype.$Loading = loading;
Vue.prototype.$codeSetConst = codeSetConst;
// Vue.prototype.$echarts = echarts


Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});




new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'sockedMsgContent') {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);

        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);

        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
};


//iframe中控制路由的变化
function receiveMessageFromIframePage (event) {
  console.log("event.data.route",event.data.route)
  if(event.data.route){
    if (event.data.data.includes('haveparams')) {
      var id = event.data.params
      router.push({path: event.data.route+"?"+event.data.params})
      console.log(router)
    } else if (event.data.data.includes('noparams')) {
      router.push({path: event.data.data.route})
    }
  }

}

window.addEventListener("message", receiveMessageFromIframePage, false);

receiveMessage("reload", function (data, node) {
  console.log("reload",node)//子页面中所填写类型为"istype"的数据
  router.push({path: '/login'})
})

//父窗体中监听消息
function receiveMessage (type, callback) {
  window.addEventListener('message', function(e) {
    if(e.data.type == type){
      callback(e.data, e)
    };
  });
};
