export default {
  enabled: false,
  repositoryId: '225153',
  apiPPrefix: '/app/mock/',
  proxy: [
    {
      url: '/admin/user/myinfo',
      method: 'get'
    }
  ]
}
