
export default [
  {
    path: 'product/productPlanList',
    name: '产品套餐',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/serviceProduct/productPlanList`),
  },
  {
    path: 'serviceProduct/productPlanDetailList',
    name: '产品套餐',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/serviceProduct/productPlanDetailList`),
  },
];
