import request from '@/router/axios';

const urlPrefix = process.env.VUE_APP_LOCAL_DEVELOPMENT == 'false' ? '/sys' : '/sys'

//根据代码集id获取代码集
export function getDicts(obj) {
  return request({
    url: urlPrefix + '/syscodeitem/getcodeitem',
    method: 'get',
    data: obj,
  });
}
//020201-05 根据当前用户 查询所有前端初始化代码项排除后的代码项 用来下拉先
export function getServerDictsList() {
  return request({
    url: urlPrefix + '/syscodeitem/getcodeitemlist',
    method: 'get',
  });
}
//020201-05 根据当前用户查询所有前端初始化代码项排除后的代码项 用来翻译
export function getServerDictsMap() {
  return request({
    url: urlPrefix + '/syscodeitem/getcodeitemmap',
    method: 'get',
  });
}

//020201-05 查询租户下的代码项集合
export function getDictsListBySetId(codeSetId) {
  return request({
    url: urlPrefix + '/code/api/tenantId/'+codeSetId+'/codeItem',
    method: 'get',
  });
}

// 分页查询代码集
export function getCodeSetListPage(obj) {
  return request({
    url: urlPrefix + '/syscodeitem/queryCodeSetList',
    method: 'post',
    data: obj
  });
}
// 新增代码集
export function addCodeItemSet(obj){
  return request({
    url: urlPrefix + '/syscodeitem/saveSysCodeSet',
    method: 'post',
    data: obj
  });
}
// 修改代码集
export function updateCodeSet(obj){
  return request({
    url: urlPrefix + '/syscodeitem/editCodeSet',
    method: 'post',
    data: obj
  })
}

//批量修改代码集状态
export function changeCodeSetStatus(obj){
  return request({
    url: urlPrefix + '/syscodeitem/changeCodeSetStatus',
    method: 'post',
    data: obj
  })
}

//分页查询代码项 
export function getCodeItemListPage(obj){
  return request({
    url: urlPrefix + '/syscodeitem/queryCodeItemList',
    method: 'post',
    data: obj
  })
}
// 新增代码项  
export function saveCodeItem(obj){
  return request({
    url: urlPrefix + '/syscodeitem/saveSysCodeItem',
    method: 'post',
    data: obj
  })
}
// 修改代码项
export function updateSysCodeItem(obj){
  return request({
    url: urlPrefix + '/syscodeitem/editSysCodeItem',
    method: 'post',
    data: obj
  })
}

// 批量修改代码项
export function changeCodeItemStatus(obj){
  return request({
    url: urlPrefix + '/syscodeitem/changeCodeItemStatus',
    method: 'post',
    data: obj
  })
}

