

import Vue from 'vue';
import VueRouter from 'vue-router';
import baseRouter from './_router';
import {parseQuery, stringifyQuery} from "@/router/query";

Vue.use(VueRouter);
// import { stringifyQuery, parseQuery } from './query' //路径位置根据实际修改

export default new VueRouter({
  stringifyQuery, // 序列化query参数
  parseQuery, // 反序列化query参数
  mode: 'history',
  strict: process.env.NODE_ENV !== 'production',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (from.meta.keepAlive) {
      from.meta.savedPosition = document.body.scrollTop;
    }
    return {
      x: 0,
      y: to.meta.savedPosition || 0,
    };
  },
  routes: baseRouter,
});
