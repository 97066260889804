
import Vue from 'vue'
import {
  setStore,
  getStore,
  removeStore,
} from '@/util/store';

const common = {

  state: {
    isCollapse: sessionStorage.getItem("isCollapse")=="true"?true:false,
    opened:[],
    templateCollapse: false,
    isFullScren: false,
    isLock: getStore({
      name: 'isLock',
    }) || false,
    dicts: getStore({
      name: 'dicts',
    }) || null,
    dictList: getStore({
      name: 'dictList',
    }) || null,
    theme: getStore({
      name: 'theme',
    }) || '#409EFF',
    lockPasswd: getStore({
      name: 'lockPasswd',
    }) || '',
    loadingStatus: {
      main: null,
      full: null,
    },
    color:localStorage.getItem("Color"),
  },
  actions: {

  },
  mutations: {
    SET_COLLAPSE: (state, data) => {
      if(data == 'open'){
        state.isCollapse = false;
        sessionStorage.setItem("isCollapse", false)
      }else if(data == 'close'){
          state.isCollapse = true;
        sessionStorage.setItem("isCollapse", true)
        }else{
        state.isCollapse = !state.isCollapse;
        sessionStorage.setItem("isCollapse", state.isCollapse)
      }
    },
    SET_OPENED: (state, action) => {
      state.opened = action;
      setStore({
        name: 'opened',
        content: state.opened,
        type: 'session',
      });
    },
    SET_DICTS: (state, dicts) => {
      state.dicts = dicts;
      setStore({
        name: 'dicts',
        content: state.dicts,
        type: 'session',
      });
    },
    SET_TEMPLATECOLLAPSE: (state, action) => {
      state.templateCollapse = !state.templateCollapse;
    },
    SET_FULLSCREN: (state, action) => {
      state.isFullScren = !state.isFullScren;
    },
    SET_LOCK: (state, action) => {
      state.isLock = true;
      setStore({
        name: 'isLock',
        content: state.isLock,
        type: 'session',
      });
    },
    SET_LOCK_PASSWD: (state, lockPasswd) => {
      state.lockPasswd = lockPasswd;
      setStore({
        name: 'lockPasswd',
        content: state.lockPasswd,
        type: 'session',
      });
    },
    CLEAR_LOCK: (state, action) => {
      state.isLock = false;
      state.lockPasswd = '';
      removeStore({
        name: 'lockPasswd',
      });
      removeStore({
        name: 'isLock',
      });
    },
    SET_LOADING_STATUS: (state, { main = null, full = null } = {}) => {
      state.loadingStatus = { main, full };
    },
    CHANGECOLOR: (state)=> {
      let textcolor;
      state.color == '#ffffff'?textcolor = '#000000':textcolor = '#ffffff'
      state.color = textcolor;
      Vue.set(state,'color',textcolor)
    }
  },
};
export default common;
