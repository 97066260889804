
const impData = {
  // 用于修改行数据
  state: {
   billMonth:'',//账单月份
   cusId:'',//客户id
   importType:"",//单客户批量客户
   bizType:'',//业务类型
   filePath:'',//上传的文件路径
   excelList:[],//上传表头列表
   sysList:[], //系统模板字段
   wageItemList:[], //系统模板包含从字段
   batchImportId:'',//导入批次id
   importTime:'',//导入时间
   novalidatePass:'',//校验未通过条数
    tipInfo:'',//友情提示
    warnInfo:'',//警告提示
    validatePass:'',//校验通过条数
    errorInfo:'',//错误信息
    resultInfo:'',//返回结果
    matchFlag:'',//是否完全匹配
    cusName:'',//客户名称
    exportFlag:'',//标识是批量还是单客户
    tempBatchId:'',// 临时批次id,薪资复制使用
  },
  mutations: {},
};

export default impData;
