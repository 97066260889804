export default [
  {
    name: '客户管理',
    path: '/cusm/cusmanager.action',
    component: () => import ( /* webpackChunkName: "cus" */ `@/views/pub/pubIndex.vue`),
  },
  {
    name: '客户跟进',
    path: '',
    component: () => import ( /* webpackChunkName: "cus" */ `@/views/cus/leads/leadsTabs.vue`),
  },
  {
    name: '客户合同',
    path: '/cus/contractList',
    component: () => import ( /* webpackChunkName: "cus" */ `@/views/cus/contract/contractList.vue`),
  },
  {
    name: '客户线索详情与修改',
    path: '/cus/leadsDetailsEdit',
    component: () => import ( /* webpackChunkName: "cus" */ `@/views/cus/leads/leadsDetailsEdit.vue`),
  },
  // {
  //   name: '雇员管理',
  //   path: '/wt/detailImportBatchTabs',
  //   component: () => import ( /* webpackChunkName: "cus" */ `@/views/cus/leads/leadsTabs.vue`),
  // }
  {
    path: '/cus/leadsTabs',
    name: '客户线索',
    component: () => import (  /* webpackChunkName: "cus" */  `@/views/cus/leads/leadsTabs.vue`),
  },
  {
    name: '客户列表',
    path: '/cus/cusList',
    component: () => import(`@/views/cus/list/cusList.vue`),
  },
  {
    name: '雇员列表',
    path: '/cus/empList',
    component: () => import(`@/views/cus/list/empList.vue`),
  },
  {
    name: '预扣预缴列表',
    path: '/ykyj/ykyjList',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/cus/ykyj/ykyjList.vue`),
  },
  {
    name: '预扣预缴导入',
    path: '/ykyj/ykyjImport',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/cus/ykyj/ykyjImport.vue`),
  },
  {
    name: '客户配置',
    path: '/cus/cusmanagerList',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/cus/payableConf/cusPayableList.vue`),
  },
  {
    name: '客户转移',
    path: '/cus/customerTransfer',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/cus/transfer/cusList.vue`),
  }
];
