import store from '../store';
import { Loading } from 'element-ui';

const mainLoading = {
  text: '拼命加载中。。。',
  target: '#main',
  fullscreen: false,
};

const fullLoading = {
  lock: true,
  text: '处理中，请稍后。。。',
  background: 'rgba(0, 0, 0, 0.7)',
};

export default {
  main() {
    const loadingStatus = store.getters.loadingStatus;
    if (!loadingStatus.main && !loadingStatus.full) {
      const loading = Loading.service(mainLoading);
      store.commit('SET_LOADING_STATUS', { main: loading });
    }
  },
  full(msg) {
    const loadingStatus = store.getters.loadingStatus;
    if (!loadingStatus.main && !loadingStatus.full) {
      if(msg){
        fullLoading.text = msg;
      } else {
        fullLoading.text = '处理中，请稍后。。。';
      }
      const loading = Loading.service(fullLoading);
      store.commit('SET_LOADING_STATUS', { full: loading });
    }
  },
  close() {
    const loadingStatus = store.getters.loadingStatus;
    if (loadingStatus.main) {
      loadingStatus.main.close();
    } else if (loadingStatus.full) {
      loadingStatus.full.close();
    }
    store.commit('SET_LOADING_STATUS', {});
  },
};
