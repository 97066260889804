
const getters = {
  tag: state => state.tags.tag,
  website: state => state.common.website,
  isCollapse: state => state.common.isCollapse,
  opened: state => state.common.opened,
  templateCollapse: state => state.common. templateCollapse,
  isLock: state => state.common.isLock,
  isFullScren: state => state.common.isFullScren,
  lockPasswd: state => state.common.lockPasswd,
  tagList: state => state.tags.tagList,
  tagCurrent: state => state.tags.tagCurrent,
  tagWel: state => state.tags.tagWel,
  accessToken: state => state.user.accessToken,
  refreshToken: state => state.user.refreshToken,
  userInfo: state => state.user.userInfo,
  roles: state => state.user.roles,
  setting: state => state.user.setting,
  loadingStatus: state => state.common.loadingStatus,
  clientWidth: state => state.screen.clientWidth,
  clientHeight: state => state.screen.clientHeight,
  tableSize: state => state.screen.tableSize,
  workspaceRect: state => state.screen.workspaceRect,
  dicts: state => state.common.dicts,
  menu: state => state.user.menu,
  pass: status => status.user.pass,
  pms: status => status.user.pms,
  tenantInfo: status => status.user.tenantInfo,
  clientInfo: status => status.user.clientInfo,
  memberInfo: status => status.user.memberInfo,
  authorizedAgencies: status => status.user.authorizedAgencies,
  authorizedMembers: status => status.user.authorizedMembers,
  color:state => state.common.color,
};
export default getters;
