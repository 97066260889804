export default [
  {
    path: '/consignee/batchIndex.action',
    name: '受托批次管理',
    component: () => import ( /* webpackChunkName: "table" */ `@/views/pub/pubIndex.vue`),
  },
  {
    path: '/consignee/feedbackIndex.action',
    name: '业务反馈',
    component: () => import ( /* webpackChunkName: "table" */ `@/views/pub/pubIndex.vue`),
  },
  {
    path: '/consignee/batchIndex.action',
    name: '受托历史记录查询',
    component: () => import ( /* webpackChunkName: "table" */ `@/views/pub/pubIndex.vue`),
  },
  {
    path: '/consignor/payoutBills_stPackedRTicket.action',
    name: '退票查询',
    component: () => import ( /* webpackChunkName: "table" */ `@/views/pub/pubIndex.vue`),
  },
  {
    path: '/consignee/differenceAction_differenceIndex.action',
    name: '汇款差额查询',
    component: () => import ( /* webpackChunkName: "table" */ `@/views/pub/pubIndex.vue`),
  },
  {
    path: '/consignee/batchAction_loadDownSendInfo.action',
    name: '待下载发放批次',
    component: () => import ( /* webpackChunkName: "table" */ `@/views/pub/pubIndex.vue`),
  },
  ,
  {
    path: '/*.action',
    name: '通用跳转',
    component: () => import ( /* webpackChunkName: "table" */ `@/views/pub/pubIndex.vue`),
  },
  {
    path: '/consignee/payment_confirm',
    name: '支付确认',
    component: () => import ( /* webpackChunkName: "table" */ `@/views/consignee/paymentConfirm.vue`),
  },
  {
    path: '/consignee/payment_confirm_detail',
    name: '支付单明细详细信息',
    component: () => import ( /* webpackChunkName: "table" */ `@/views/consignee/paymentConfirmDetail.vue`),
  }
];
