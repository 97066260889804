
const Layout = () => import ( /* webpackChunkName: "index" */ `@/page/index/index.vue`);
import demo from './demo/demo';
//import wt from './wt/wt';

import cus from './cus/cus';
import entrust from './entrust/entrust';
import agreement from './agreement/agreement';
import consignee from './consignee/consignee';
import product from './product/product';
import org from './org/org';
import report from './report/report';
import setting from './setting/setting';
import bankDirect from './bank_direct/bank_direct';
import paymentMenus from './payment/payment';
import help from './help/help';
//import pub from './pub/pubtest';
//import product from './serviceProduct/serviceProduct';
import subRouter from './subrouter';
let rou = subRouter.concat(demo)
//rou =rou.concat(wt);//委托菜单
rou =rou.concat(product);//服务产品
rou =rou.concat(cus);//客户
rou =rou.concat(entrust);//
rou =rou.concat(agreement);//
rou =rou.concat(consignee);//
rou =rou.concat(org);//
rou =rou.concat(report);//
rou =rou.concat(setting);//
rou =rou.concat(bankDirect);//
rou =rou.concat(paymentMenus);//
rou =rou.concat(help);//
export default [
  {
    path: '/',
    name: '主页',
    redirect: '/login',
  },
  {
    path: '/',
    component: Layout,
    children:rou,
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true,
  },
  {
    path: '/:tenant/login',
    name: '登录',
    component: () => import ( /* webpackChunkName: "login" */ `@/page/new-login/index.vue`),
  },
  {
    path: '/login',
    name: '登录',
    component: () => import ( /* webpackChunkName: "login" */ `@/page/new-login/index.vue`),
  },
  {
    path: '/lock',
    name: '锁屏',
    component: () => import ( /* webpackChunkName: "lock" */ `@/page/lock/index.vue`),
  },

  {
    path: '/404',
    component: () => import ( /* webpackChunkName: "404" */ `@/components/ciic/error-page/404.vue`),
    name: '404',
  },
  {
    path: '/403',
    component: () => import ( /* webpackChunkName: "403" */ `@/components/ciic/error-page/403.vue`),
    name: '403',
  },
  {
    path: '/500',
    component: () => import ( /* webpackChunkName: "500" */ `@/components/ciic/error-page/500.vue`),
    name: '500',
  },
];
