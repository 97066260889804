import { getStore, setStore } from '@/util/store';
import platformMenus from '@/menus/demo/demo';
import {
  cacheUser,
  getFacilitatorId,
  getUserInfo,
  loginByUsername,
  loginByPhone,
  logout,
 requestRefreshToken
} from '@/api/user/login';

const user = {
  state: {
    userInfo: getStore({
      name: 'userInfo',
    }) || {},
    roles: getStore({
      name: 'roles',
    }) || [],
    setting: getStore({
      name: 'setting',
    }) || [],
    accessToken: getStore({
      name: 'accessToken',
    }) || '',
    refreshToken: getStore({
      name: 'refreshToken',
    }) || '',
    pass: getStore({
      name: 'pass'
    }) || [],
    pms: getStore({
      name: 'pms'
    }) || {
      menu: new Set(),
      button: new Set(),
      other: new Set(),
    },
    menu: getStore({
      name: 'menu'
    }) || new Set(),
    tenantInfo: getStore({
      name: 'tenantInfo'
    }) || {},
    clientInfo:getStore({
      name: 'clientInfo'
    }) || {},
    memberInfo:getStore({
      name: 'memberInfo'
    }) || {},
    authorizedAgencies:getStore({
      name: 'authorizedAgencies'
    }) || new Set(),
    authorizedMembers:getStore({
      name: 'authorizedMembers'
    }) || new Set()
  },
  actions: {
    // 设置Token的值及失效时间间隔
    RefreshToken({
      commit,
      state,
    }) {
      return new Promise((resolve, reject) => {
        requestRefreshToken(state.refreshToken).then((resp) => {
          if(resp.data.code === 406){
            reject(resp);
          } else {
            commit('SET_ACCESS_TOKEN', resp.data.access_token);
            commit('SET_REFRESH_TOKEN', resp.data.refresh_token);
            resolve();
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },

    // 根据用户名、手机号、邮箱登录
    LoginByUsername({
        commit,
        state,
        dispatch,
      }, userInfo) {
        console.log('登录测试44444444444444444444444',userInfo)
      return new Promise((resolve, reject) => {
        console.log('登录测试3333333333333333',resolve)
        const user = userInfo;
        loginByUsername(user.username, user.password, user.validCode, user.isSwitch, user.personId).then((response) => {
          console.log('登录测试111111111111111',response)
          if(response.data.data.success=="true"){
            const data = response.data.data.user;
            console.log('登录测试',data)
            commit('SET_ACCESS_TOKEN', response.data.data.accessToken);
            //commit('SET_REFRESH_TOKEN', data.refresh_token);

            //commit('SET_PASS', data.pass);
            commit('SET_PMS', data.pmsList);
            commit('SET_USER_INFO', data);
            commit('SET_CLIENT_INFO', {id:data.clientId});
            commit('SET_TENANT_INFO', {id:data.tenantId});
            commit('SET_MEMBER_INFO', {id:data.memberId});
            commit('SET_AUTHORIZED_MEMBERS', data.authorizedMembers);
            commit('SET_AUTHORIZED_AGENCIES', data.authorizedAgencies);

            commit('CLEAR_LOCK');
            resolve(response);
          }
          reject(response);
        }).catch((error) => {
          console.log('登录测试22222222222222222',error)
          reject(error);
        });
      });


    },
    // 根据手机号登录
    LoginByPhone({
       commit,
       state,
       dispatch,
     }, userInfo) {
      return new Promise((resolve, reject) => {
        const mobile = userInfo.mobile.trim();
        loginByPhone(mobile, userInfo.code).then((response) => {
          if(!response.data.code){
            const data = response.data;
            commit('SET_ACCESS_TOKEN', data.access_token);
            commit('SET_REFRESH_TOKEN', data.refresh_token);

            commit('SET_PASS', data.pass);
            commit('SET_PMS', data.pms);
            commit('SET_USER_INFO', {id:data.userId});
            commit('SET_CLIENT_INFO', {id:data.clientId});
            commit('SET_TENANT_INFO', {id:data.tenantId});
            commit('SET_MEMBER_INFO', {id:data.memberId});
            commit('SET_AUTHORIZED_MEMBERS', data.authorizedMembers);
            commit('SET_AUTHORIZED_AGENCIES', data.authorizedAgencies);

            commit('CLEAR_LOCK');
            resolve(response);
          }
          reject(response);
        }).catch((error) => {
          reject(error);
        });
      });

    },

    // 缓存用户相关信息
    CacheUser({
      commit,
      state,
    }, { orgId = '', createOrgId = '' }) {
      return new Promise((resolve, reject) => {
        cacheUser(state.userInfo.id, orgId, createOrgId).then((response) => {
          const { enterprise, facilitator, member, unitCustomer, operator } = response.data;
          commit('SET_FACILITATOR_INFO', facilitator);
          commit('SET_ENTERPRISE_INFO', enterprise);
          commit('SET_MEMBER_INFO', member);
          commit('SET_UNIT_CUSTOMER_INFO', unitCustomer);
          commit('SET_OPERATOR_INFO', operator);
          commit('CLEAR_LOCK');
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    // 登出
    LogOut({
         commit,
         state,
       }) {
      return new Promise((resolve, reject) => {
        logout(state.accessToken, state.refreshToken).then(() => {
          commit('SET_ACCESS_TOKEN', '');
          commit('SET_REFRESH_TOKEN', '');
          commit('SET_MENU', []);
          commit('SET_PASS', {});
          commit('SET_PMS', {});
          commit('SET_USER_INFO', {});
          commit('SET_CLIENT_INFO', {});
          commit('SET_TENANT_INFO', {});
          commit('SET_MEMBER_INFO', {});
          commit('SET_AUTHORIZED_MEMBERS', '');
          commit('SET_AUTHORIZED_AGENCIES', '');

          commit('DEL_ALL_TAG');
          resolve();
        }).catch((error) => {

          commit('SET_ACCESS_TOKEN', '');
          commit('SET_REFRESH_TOKEN', '');
          commit('SET_MENU', []);
          commit('SET_PASS', {});
          commit('SET_PMS', {});
          commit('SET_USER_INFO', {});
          commit('SET_CLIENT_INFO', {});
          commit('SET_TENANT_INFO', {});
          commit('SET_MEMBER_INFO', {});
          commit('SET_AUTHORIZED_MEMBERS', '');
          commit('SET_AUTHORIZED_AGENCIES', '');

          commit('DEL_ALL_TAG');
          reject();
        });
      });
    },
    // 注销session
    FedLogOut({
      commit,
    }) {
      return new Promise((resolve) => {
        // 清除菜单
        commit('SET_MENU', []);
        commit('SET_SETTING', []);
        // 清除权限
        commit('SET_PERMISSIONS', new Set());
        // 清除用户信息
        commit('SET_USER_INFO', {});
        commit('SET_ACCESS_TOKEN', '');
        commit('SET_REFRESH_TOKEN', '');
        commit('SET_FACILITATOR_ID', '');
        commit('SET_FACILITATOR_PATH', '');
        commit('SET_FACILITATOR_NAME', '');
        commit('SET_ROLES', []);
        commit('DEL_ALL_TAG');
        // removeToken();

        resolve();
      });
    },
  },
  mutations: {
    SET_ACCESS_TOKEN: (state, accessToken) => {
      state.accessToken = accessToken;
      setStore({
        name: 'accessToken',
        content: state.accessToken,
        type: 'session',
      });
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo;
      setStore({
        name: 'userInfo',
        content: state.userInfo,
        type: 'session',
      });
    },
    SET_MENU: (state, menu) => {
      state.menu = menu;
      setStore({
        name: 'menu',
        content: state.menu,
        type: 'session',
      });
    },
    SET_PMS: (state, pms) => {
      state.pms = pms;
      setStore({
        name: 'pms',
        content: state.pms,
        type: 'session',
      });
    },
    SET_PASS: (state, pass) => {
      state.pass = pass;
      setStore({
        name: 'pass',
        content: state.pass,
        type: 'session',
      });
    },
    SET_TENANT_INFO: (state, tenantInfo) => {
      state.tenantInfo = tenantInfo;
      setStore({
        name: 'tenantInfo',
        content: state.tenantInfo,
        type: 'session',
      });
    },
    SET_REFRESH_TOKEN: (state, refreshToken) => {
      state.refreshToken = refreshToken;
      setStore({
        name: 'refreshToken',
        content: state.refreshToken,
        type: 'session',
      });
    },
    SET_MEMBER_INFO: (state, memberInfo) => {
      state.memberInfo = memberInfo;
      setStore({
        name: 'memberInfo',
        content: state.memberInfo,
        type: 'session',
      });
    },
    SET_CLIENT_INFO: (state, clientInfo) => {
      state.clientInfo = clientInfo;
      setStore({
        name: 'clientInfo',
        content: state.clientInfo,
        type: 'session',
      });
    },
    SET_AUTHORIZED_MEMBERS: (state, authorizedMembers) => {
      state.authorizedMembers = authorizedMembers;
      setStore({
        name: 'authorizedMembers',
        content: state.authorizedMembers,
        type: 'session',
      });
    },
    SET_AUTHORIZED_AGENCIES: (state, authorizedAgencies) => {
      state.authorizedAgencies = authorizedAgencies;
      setStore({
        name: 'authorizedAgencies',
        content: state.authorizedAgencies,
        type: 'session',
      });
    },
    SET_SETTING: (state, setting) => {
      state.setting = setting;
      setStore({
        name: 'setting',
        content: state.setting,
        type: 'session',
      });
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
      setStore({
        name: 'roles',
        content: state.roles,
        type: 'session',
      });
    },

  }
};
export default user;
