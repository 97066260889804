export default [
  /*{
    path: 'wt/detailImportBatchTabs',
    name: '批次列表',
    component: () => import ( /!* webpackChunkName: "serveTable" *!/ `@/views/cus/leads/leadsTabs.vue`),
  },*/
  {
    name: '操作手册',
    path: 'help',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/help/help.vue`),
  }
];
