import request from '@/router/axios'
import { commonConst } from '@/const/code.js'

export const loginByUsername = (username, password, validCode, isSwitch, personId) => {   //登录接口
  const grant_type = 'password';
  const scope = 'server';
  //const tenantId = commonConst.TENANT_ID;
  console.log("username",username)
  return request({
    url: '/login/login',
    headers: {
       // Authorization: 'Basic d2FnZTp3YWdl',
      //Authorization: 'Basic Y2lpYy1jbG91ZC1wbGF0Zm9ybTpjaWljLWNsb3VkLXBsYXRmb3Jt',
    },
    method: 'post',
    params: { loginName: username, password, validCode, isSwitch, personId},
    data: { loginName: username, password, validCode, isSwitch, personId},
  });
};
export function getUser() {
  return request(
    {
      url: '/getUser',
      method:'get'
    }
  )
}

export function loginToken(obj) {
  return request(
    {
      url: '/userlogin/'+obj,
      method:'get'
    }
  )
}



export const requestRefreshToken = (refresh_token) => {
  const grant_type = 'refresh_token';
  const scope = 'server';
  const type = '1';
  const tenantId = commonConst.TENANT_ID;
  return request({
    url: '/auth/oauth/token',
    headers: {
      // Authorization: 'Basic d2FnZTp3YWdl',
    },
    method: 'post',
    params: { grant_type, scope, refresh_token,type,tenantId},
  });
};



export const logout = (accesstoken, refreshToken) => request({   //tod  退出接口
  url: '/login/out',
  method: 'post',
  params: { accesstoken, refreshToken },
  data: { accesstoken, refreshToken },
});
export const cacheUser = (userId, orgId, createOrgId) => request({ //todo  缓存用户信息
  url: '/user/user/cache',
  method: 'post',
  data: { userId, orgId, createOrgId },
});


/**
 * 获取系统维护信息
 */
export function getMaintainInfo() {
  return request({
    url: `/login/getMaintainInfo`,
    method: 'post',
  });
}



