/**
 * 菜单
 */

var platform = () =>import ( /* webpackChunkName: "platform" */ `@/page/statistics/platform.vue`)


export default [{
  path: 'index',
  name: '平台',
  component: platform,
},
  {
    path: 'person/edit',
    name: '客户统计查询',
    component: () => import ( /* webpackChunkName: "userEdit" */ `@/views/demo/user/userEdit.vue`)
  },
  {
    path: 'import/excle',
    name: '个人信息',
    component: () => import ( /* webpackChunkName: "batchParent" */ `@/views/demo/serve/batch/batchParent.vue`)
  },
  {
    path: 'static/form',
    name: '静态表单',
    component: () => import ( /* webpackChunkName: "form" */ `@/views/demo/form/staticform.vue`),
  }
];
