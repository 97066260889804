<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>

  export default {
    name: 'app',
    provide () {
      return {
        reload: this.reload
      }
    },
    data() {
      return {
        isRouterAlive:true
      };
    },
    watch: {},
    created() {
      this.$store.commit('SET_CLIENT_WIDTH',document.body.clientWidth)
      this.$store.commit('SET_CLIENT_HEIGHT',document.body.clientHeight)
      this.$store.commit('SET_TABLE_SIZE')
    },
    methods: {
      reload () {
        this.isRouterAlive = false
        this.$nextTick(function () {
          this.isRouterAlive = true
        })
      }
    },
    computed: {},
  };
</script>
<style lang="scss">
  #app {
    width: 100%;
    height: 100%;
    overflow:hidden;
  }
  .el-pagination {
    text-align: right;
    margin-top: 10px;
  }
  /*.v-modal{*/
  /*  z-index: 10 !important;*/
  /*}*/
</style>
