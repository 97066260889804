/**
 *
 * http配置
 *
 */
import axios from 'axios';
import router from './router';
import store from '../store';
import {message} from './../resetMessage';
import errorCode from '@/const/errorCode';
// import NProgress from 'nprogress';
// import 'nprogress/nprogress.css';
import mock from '@/api/mock/mock';
import {AESEncrypt} from "@/cryptoAES";

// 超时时间
axios.defaults.timeout = 1800000;
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true;
// 重新请求标志位，避免无限循环请求
axios.defaults.isRetryRequest = false;

axios.defaults.validateStatus = function validateStatus(status) {
  return /^(2|3)\d{2}$/.test(status) || status === 401;
};

// 刷新token的状态 0-未刷新 1-正在刷新 2-刷新失败
let refreshingTokenStatus = 0;
// 设置请求默认前缀
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;


// NProgress.configure({ showSpinner: false });

/**
 * 刷新token处理
 * @param config axios对象
 * @returns {Promise<*>}
 */
async function handleRefreshToken(config) {
  let resultData = null;
  // 第一次发起的请求401才能请求token
  if (!config.isRetryRequest) {
    // 存在请求正在刷新token，定时查看刷新状态，等待刷新结束
    if (refreshingTokenStatus === 1) {
      // 添加promise和await保证定时器同步
      const refreshRequest = new Promise((resolve, reject) => {
        const timer = setInterval(() => {
          // 请求token成功，重新请求之前失败的请求
          if (refreshingTokenStatus === 0) {
            clearInterval(timer);
            // 重新设置token
            config.headers.Authorization = `Bearer ${store.getters.accessToken}`
            /*
             这边不需要baseURL是因为会重新请求url
             url中已经包含baseURL的部分了
             如果不修改成空字符串，会变成'api/api/xxxx'的情况
             */
            config.baseURL = '';
            // 重新请求
            axios(config).then(res => {
              resolve(res);
            }).catch(error => {
              reject(error);
            });
            // 请求token失败，清除定时器
          } else if (refreshingTokenStatus === 2) {
            clearInterval(timer);
          }
        }, 100);
      });
      await refreshRequest.then(res => {
        resultData = res;
      })
      // 不存在正在刷新token的请求，刷新token并重新请求
    } else {
      // 标识当前token状态为正在刷新
      refreshingTokenStatus = 1;
      // 标识该请求为失败后重新发起的请求
      config.isRetryRequest = true;
      await store.dispatch('RefreshToken')
        .then(() => {
          // 重新设置token
          config.headers.Authorization = `Bearer ${store.getters.accessToken}`
          /*
           这边不需要baseURL是因为会重新请求url
           url中已经包含baseURL的部分了
           如果不修改成空字符串，会变成'api/api/xxxx'的情况
           */
          config.baseURL = '';
          // 重新请求
          return axios(config);
        }).then((res) => {
          // 重置是否正在刷新Token标识
          refreshingTokenStatus = 0;
          resultData = res;
        }).catch((error) => {
          // 标识当前token状态为刷新失败
          refreshingTokenStatus = 2;
        });
    }
    // 重新请求过的请求如token还是无效，无法再次刷新token
  } else {
    toLogin();
  }
  return resultData;
}


/**
 * 错误处理，自动跳转登录页并提示
 */
function toLogin() {
  message.error('登录信息过期，请重新登录')

  // 刷新失败跳转登录页面
  let pathStr = '';
  if (store.getters.facilitatorPath) {

    pathStr = `/${store.getters.facilitatorPath}/login`;
  } else {
    pathStr = '/login';
  }
  store.dispatch('FedLogOut').then(dd => {
    router.push({path: pathStr});
  });
}


// HTTPrequest拦截
axios.interceptors.request.use((config) => {
  console.log("token=======", store.getters.accessToken)
    console.log("=================config",config)
  let isEncryption = true
  if (config.data instanceof FormData) {
    // 遍历 FormData 对象中的所有键值对
    for (let pair of config.data.entries()) {
      const [key, value] = pair;
      // 检查值是否为 File 对象
      if (value instanceof File) {
        isEncryption = false
        break; // 找到文件后可以停止检查
      }
    }
  }
  if (config.data && isEncryption) {
    let AESEncryptData = AESEncrypt(JSON.stringify(config.data));
    console.log("AESEncryptData", AESEncryptData)
    config.data = {
      req: AESEncryptData
    }
  }

  if (config.params && isEncryption) {
    let AESEncryptData = AESEncrypt(JSON.stringify(config.params));
    console.log("AESEncryptData", AESEncryptData)
    config.params = {
      req: AESEncryptData
    }
  }
  // NProgress.start() // start progress bar
  if (store.getters.accessToken) {
    if (!config.headers.Authorization) {
      // 让每个请求携带token--['X-Token']为自定义key 请根据实际情况自行修改
      config.headers.Authorization = `Bearer ${store.getters.accessToken}`;

    }
  }
  // 只在开发时期用 上找或测试时要删除
  // if(!process.env.VUE_APP_LOCAL_DEVELOPMENT){

  config.headers['Priority-Host'] = process.env.VUE_APP_LOCA_ADDR;
  console.log("============");
  console.log(config.method);
  if (config.method === 'get') {
    config.params = {
      t: Date.parse(new Date()) / 1000,
      ...config.params
    }
  }

  // config.headers['Priority-Host'] ='192.168.43.193';
  //}
  /*if(!process.env.VUE_APP_LOCAL_DEVELOPMENT&&config.url.indexOf('sys') >=1){
    config.headers['Priority-Host'] =process.env.VUE_APP_SYS_URL;
  }*/

  if (mock.enabled && process.env.NODE_ENV === 'development') {
    const proxy = mock.proxy;
    for (let i = 0; i < proxy.length; i++) {
      // 匹配到需要mock的接口，替换服务端地址为mock服务器地址
      if (proxy[i].url === config.url && proxy[i].method === config.method) {
        config.baseURL = mock.apiPPrefix + mock.repositoryId;
        break;
      }
    }
  }
  console.log("发起访问请求：", config);
  return config;
}, error => Promise.reject(error));


// HTTPresponse拦截
axios.interceptors.response.use(async (data) => {
  let resultData = data;
  const {msg, code} = data.data;
  const {status, config} = data;
  console.log("=========statusstatusstatusstatusstatus========",data)
  // token过期或被替换
  if (status === 200) {
    if (code > 0) {
      if (code === 403) { // token无效或过期
        resultData = handleRefreshToken(config);
      } else if (code === 406 || code === 401) { // 刷新token无效或过期
        toLogin();
        return false;
      } else {
        message.error(msg || errorCode.default);
      }
    }
  }
  if (status === 500){
    message.error(msg || errorCode.default);
  }
  // token 过期无效去登录页面
  if (status === 401) {
    toLogin();
    return false

  }
  return resultData;
}, (error) => {
  if (error && error.response) {
    const {status} = error.response;
    console.log("--------------err",error)

    if (status) {
      message.error(errorCode[status] || errorCode.default);

    }
  }
  return Promise.reject(new Error(error));
});


export default axios;
