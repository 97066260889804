export default [
  {
    path: '/wt/paymentDetail',
    name: '支付单明细',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/payment/payment.vue`),
  },
  {
    path: '/wt/paymentDetailInfo',
    name: '支付单明细详细信息',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/payment/paymentDetailInfo.vue`),
  },
  {
    path: '/wt/printPaymentSlip',
    name: '打印财务公司支出单',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/payment/print/printPaymentSlip.vue`),
  },
  {
    path: '/wt/printRefundNotice',
    name: '打印退票通知书',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/payment/print/printRefundNotice.vue`),
  },
  {
    path: '/wt/printEmployeeDetail',
    name: '打印雇员明细',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/payment/print/printEmployeeDetail.vue`),
  },
  {
    path: '/wt/printPublicPayment',
    name: '打印对公支付单',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/payment/print/printPublicPayment.vue`),
  },
  {
    path: '/wt/printPublicPaymentDetail',
    name: '打印对公支付单',
    component: () => import ( /* webpackChunkName: "serveTable" */ `@/views/payment/print/printPublicPaymentDetail.vue`),
  },
];
